<template>
    <div class="buyPhoto">
        <div class="header">
            <div data-v-b2e6e072="" class="container"
                style="display: flex; align-items: center; justify-content: space-between;">
                <div data-v-b2e6e072="" class="buyText">
                    <h4>{{$t('nav.Sotib olingan rasmlar')}}: {{ galleries.length }} </h4>
                    <hr>
                </div>
            </div>
        </div>

        <div v-if="galleries.length !== 0" class="container-fluid bg-trasparent my-4 p-3"
            style="position: relative; height: 100%;">
            <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                <div class="col" v-for="(product, i) in galleries" :key="i">
                    <div class="card h-100 shadow-sm">
                        <img :src="'https://api.stateartmuseum.uz/storage/' + product.preview" class="card-img-top"
                            alt="...">
                        <div class="card-body">
                            <div class="clearfix mb-3">
                                <span data-v-b2e6e072="" class="float-start badge rounded-pill bg-primary text-white">
                                    {{ product.name[`${$i18n.locale}`] }} </span>
                                <span style="font-size: 18px" class="float-start badge rounded-pill">{{ product.price }}
                                    $</span>
                            </div>
                            <p class="card-title" v-html="product.description[$i18n.locale]"></p>
                            <div class="text-center my-4">
                                <button @click="buyPhoto(product.id)" class="btn btn-warning"> Yuklab olish </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container-fluid bg-trasparent my-4 p-3" style="position: relative; margin-bottom: 100px;">
            <h4 style="margin-bottom: 100vh">
                {{$t('nav.Sizda hozirda hech qanday rasmlar yoq !')}}
            </h4>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Gallery',
    data() {
        return {
            galleries: [],
            token: localStorage.getItem('muzey_token')
        }
    },
    methods: {
        async getGallery() {
            const data = await axios.get(`https://api.stateartmuseum.uz/public/api/profile/photos/${localStorage.getItem('muzey-user-id')}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                }
            )
            this.galleries = data.data
        },
        async buyPhoto(id) {
            if (this.token) {
                try {
                    const buy = await axios.post('https://api.stateartmuseum.uz/public/api/payment/checkout-url', { photo_id: id },
                        {
                            headers: {
                                Authorization: `Bearer ${this.token}`
                            }
                        }
                    )
                    console.log(buy);
                } catch (error) {
                  console.log(error);
                    this.$toast.error(error.response, {
                    position: 'bottom-right'
                })
                }
            }
            else {
                this.$toast.error(`Iltimos ro'yhatdan o'ting !`, {
                    position: 'bottom-right'
                })
            }
        }
    },
    created() {
        this.getGallery()
    }
}
</script>

<style scoped>
.buyPhoto {
    padding-top: 1rem;
    background: #f5f7f9;
}

.buyPhoto .header {
    background: rgb(245 242 239);
    height: 100px;
}

.buyPhoto h4 {
    padding-top: 36px;
    /* text-align: center; */
    font-weight: bold;
    font-size: 18px;
    color: #989292;
}

.buyPhoto .container-fluid {
    max-width: 1200px
}

.buyPhoto .row {
    margin-top: 2rem;
}

.buyPhoto .col {
    padding: 15px;
}

.card {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    border: 0;
    border-radius: 1rem
}

.buyPhoto .btn[data-v-a26ab748] {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 50px 5px 50px;
}

.buyPhoto .card-img,
.buyPhoto .card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px)
}

.buyPhoto .card h5 {
    overflow: hidden;
    height: 56px;
    font-weight: 900;
    font-size: 1rem
}

.buyPhoto .card-img-top {
    width: 100%;
    height: 280px;
    object-fit: cover;
    padding: 20px
}

.buyPhoto .text-center {
    padding-top: 28px;
}

.buyPhoto .card h2 {
    font-size: 1rem
}

.buyPhoto .card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)
}

.buyPhoto .label-top {
    position: absolute;
    background-color: #8bc34a;
    color: #fff;
    top: 8px;
    right: 8px;
    padding: 5px 10px 5px 10px;
    font-size: .7rem;
    font-weight: 600;
    border-radius: 3px;
    text-transform: uppercase
}

.top-right {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 1rem;
    font-weight: 900;
    background: #ff5722;
    line-height: 90px;
    text-align: center;
    color: white
}

.top-right span {
    display: inline-block;
    vertical-align: middle
}

@media (max-width: 768px) {
    .card-img-top {
        max-height: 250px
    }
}

.over-bg {
    background: rgba(53, 53, 53, 0.85);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(0.0px);
    -webkit-backdrop-filter: blur(0.0px);
    border-radius: 10px
}

.buyPhoto .btn {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 50px 5px 50px
}

.box .btn {
    font-size: 1.5rem
}

@media (max-width: 1025px) {
    .btn {
        padding: 5px 40px 5px 40px
    }
}

@media (max-width: 250px) {
    .btn {
        padding: 5px 30px 5px 30px
    }
}

.btn-warning {
    background: none #f7810a;
    color: #ffffff;
    fill: #ffffff;
    border: none;
    text-decoration: none;
    outline: 0;
    box-shadow: -1px 6px 19px rgba(247, 129, 10, 0.25);
    border-radius: 100px
}

.btn-warning:hover {
    background: none #ff962b;
    color: #ffffff;
    box-shadow: -1px 6px 13px rgba(255, 150, 43, 0.35)
}

.bg-success {
    font-size: 1rem;
    background-color: #f7810a !important
}

.bg-danger {
    font-size: 1rem
}

.price-hp {
    font-size: 1rem;
    font-weight: 600;
    color: darkgray
}

.amz-hp {
    font-size: .7rem;
    font-weight: 600;
    color: darkgray
}

.fa-question-circle:before {
    color: darkgray
}

.fa-plus:before {
    color: darkgray
}

.box {
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12)
}



.thumb-sec {
    max-width: 300px
}

@media (max-width: 576px) {
    .box-img {
        max-width: 200px
    }

    .thumb-sec {
        max-width: 200px
    }
}

.inner-gallery {
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin: 1px;
    display: inline-block;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover
}

@media (max-width: 370px) {
    .box .btn {
        padding: 5px 40px 5px 40px;
        font-size: 1rem
    }
}

.disclaimer {
    font-size: .9rem;
    color: darkgray
}

.related h3 {
    font-weight: 900
}
</style>